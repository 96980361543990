import React from 'react'
import { useStaticQuery, graphql } from "gatsby"
import { StaticImage, GatsbyImage, getImage } from "gatsby-plugin-image"
import useTranslations from "../../hooks/useTranslations"
import { LocaleContext } from "../../components/layout"

import LocalizedLink from "../../components/localizedLink"
import Hero from "../../components/hero"


const TourOverviewPageView = () => {
  const { locale } = React.useContext(LocaleContext)
  const { toursPage, abbreviation } = useTranslations()
  const data = useStaticQuery(graphql`
    {
      allGoogleTourenSheet {
        nodes {
          titleDe
          titleEn
          slugDe
          textDe
          featuredImage
          fahrzeit
          fahrzeitEinheitDe
          fahrzeitEinheitEn
          schleusen
        }
      }
      tourImages: allFile(filter: {sourceInstanceName: {eq: "tourImages"}}) {
        nodes {
          base
          childImageSharp {
            gatsbyImageData(
              width: 640
              aspectRatio: 1.49
            )
          }
        }
      }
      heroImage: allFile(filter: {sourceInstanceName: {eq: "images"}}) {
        nodes {
          base
          childImageSharp {
            gatsbyImageData(
              width: 1920
            )
          }
        }
      }
    }
  `)
  
  const getHeroImg = data.heroImage.nodes.find(element => {
    if (element.base === `discover-berlin.jpeg`) {
      return true;
    }
    return false;
  });
  const heroImg = getImage(getHeroImg)
  const heroHeightScreen = true // true set height to 100vh

  return (
    <>
      <Hero 
        hScreen={heroHeightScreen}
        heroTextAlignmentCenter={true}
        preTitle={toursPage.discover}
        title={toursPage.berlinAndBrandenburg}
        heroImg={heroImg}
      />

      <section className="relative mt-10 md:mt-32">
        <div className="grid gap-6 px-4 pb-48 mx-auto lg:grid-cols-3 max-w-7xl sm:px-6 lg:pb-64">
          
          <div className="mb-10 prose">
            <h2>{toursPage.shortDayTrips}</h2>
            <p>{toursPage.shortDayTripsText}</p>
            <LocalizedLink to="#" className="">{toursPage.showAll}</LocalizedLink>
          </div>

          <div className="grid grid-flow-row gap-6 lg:col-span-2 md:grid-cols-2">
            {data.allGoogleTourenSheet.nodes.map(node => {
              const title = locale === 'de' ? node.titleDe : node.titleEn
              const fahrzeitEinheit = locale === 'de' ? node.fahrzeitEinheitDe : node.fahrzeitEinheitEn
              const featuredImage = data.tourImages.nodes.find(element => {
                if (element.base === `${node.featuredImage}.jpg`) {
                  return true;
                }
                return false;
              });
              const image = getImage(featuredImage)
              return(
                <div className="grid grid-cols-1 bg-white shadow-md" key={node.slugDe}>
                  <div className="inline-block overflow-hidden">
                    <LocalizedLink to={`/touren/${node.slugDe}`} >
                      {image && 
                        <GatsbyImage image={image} alt={node.titleDe} className="duration-700 scale-105 hover:scale-100"
                      />}
                    </LocalizedLink>
                  </div>
                  <div className="flex p-4">
                    <h3 className="text-lg font-semibold text-slate-700">
                      <LocalizedLink to={`/touren/${node.slugDe}`}>{title}</LocalizedLink>
                    </h3>
                    <div className="ml-auto min-w-max text-slate-500">{`${abbreviation.approx} ${node.fahrzeit} ${fahrzeitEinheit}`}</div>
                  </div>
                </div>
              )
            })}
          </div>

        </div>

        <StaticImage className="absolute bottom-0 left-0 right-0 -z-10" src="../../assets/images/treptow.jpeg" />
      </section>
    </>
  );
};

export default TourOverviewPageView;