import * as React from "react";
import { GatsbyImage } from "gatsby-plugin-image";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const Hero = (props) => {
  return (
    <div
      className={classNames(props.hScreen && `h-screen max-h-[1000px]`, "grid")}
    >
      <GatsbyImage
        image={props.heroImg}
        alt=""
        className="col-start-1 row-start-1"
      />
      <div
        className={classNames(
          props.heroTextAlignmentCenter ? `place-items-center` : `items-center`,
          "relative grid col-start-1 row-start-1"
        )}
      >
        <div>
          <h1
            className={classNames(
              props.heroTextAlignmentCenter && `text-center`,
              "text-white drop-shadow px-6 container mx-auto"
            )}
          >
            <span className="uppercase">{props.preTitle}</span>
            <br />
            <span className="text-2xl font-semibold md:text-4xl">
              {props.title}
            </span>
          </h1>
          {props.children && (
            <div className="px-6 mx-auto">{props.children}</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Hero;
